<template>
  <Modal
    :show="isShow"
    :title="title"
    :errors="errors"
    full
    @cansel="$emit('close')"
    @ok="handlerSubmit"
  >
    <div class="presetio-modals-modificated" ref="formContainer">
      <CRow>
        <CCol sm="3">
          <CInput v-model="form.value" label="Color" placeholder="Color"/>
        </CCol>
      </CRow>
    </div>
  </Modal>
</template>

<script>
import moment from "moment";

import MixinsScreenModal from "@/mixins/screenModal";

export default {
  name: "NumberModalsModificated",

  mixins: [MixinsScreenModal],

  components: {},

  data: () => ({
    form: {
      value: null
    }
  }),

  computed: {
    title() {
      if (this.isShow) {
        return this.data ? "Edit Instapano color" : "Add Instapano color";
      }
    }
  },

  created() {
    for (let lang of this.languages) {
      this.form[`name_${lang}`] = null;
    }
  }
};
</script>

<style lang="scss" scoped>
.presetio-modals-modificated {
  &__preset {
    padding: 10px;

    display: flex;
    flex-wrap: wrap;

    border: 1px solid #768192;
  }
}
</style>
